import api
  from '../helpers/api'
import { getLanguage }
  from "../services/token";
// export const BASE_URL = 'https://app.tendoo.com/api';
export const BASE_URL = 'https://nesolutendoowebtest20191003073447.azurewebsites.net/api/';

export function getVideoActions(videoId, peopleNumber, saveVideoActions) {
  api.get(`/video/${videoId}/action?Persons=${peopleNumber}&LangId=${getLanguage}`)
    .then(response => {
      saveVideoActions(response)
    })
    .catch((err) => {
      console.log("GET VIDEO ACTIONS ERROR", err)
    })
}
