import React from 'react'

const RecipeValues = (props) => {
  let { translate } = props
  return(
    <section className="recipes-values section-top-90">
        <div className="container">
            <div className="row eqaul">
                <div className="col-xs-12 col-sm-6 col-md-6">
                    <div className="recipes-values-wrap">
                        <h1>{translate('app.recipeDetails.nutritional_values_per_serving')}</h1>
                        <div className="recipes-table">
                            <table>
                              <tbody>
                                  <tr>
                                      <th>{translate('app.recipeDetails.kcal')}</th>
                                      <th>{translate('app.recipeDetails.eiweiss')}</th>
                                      <th>{translate('app.recipeDetails.fett')}</th>
                                      <th>{translate('app.recipeDetails.kohlenh')}.</th>
                                  </tr>
                                  <tr>
                                      <td>{props.kcal}</td>
                                      <td>{props.protein} g</td>
                                      <td>{props.fat} g</td>
                                      <td>{props.carbos} g</td>
                                  </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6">
                    <div className="recipes-time-wrap">
                        <div className="recipes-time-item">
                            <svg width="34px" height="34px" viewBox="0 0 34 34" version="1.1" xmlns="http://www.w3.org/2000/svg"
                            // xmlns:xlink="http://www.w3.org/1999/xlink"
                            ><g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="Recipes-Details-Screen" transform="translate(-846.000000, -1074.000000)" fill="#FF5F00" fillRule="nonzero"><g id="time" transform="translate(735.000000, 1044.000000)"> <g id="Group-5-Copy-7" transform="translate(111.000000, 30.000000)"><g id="icon-1"><path d="M0,17 C0.0618181818,16.8964545 17,17 17,17 C17,17 17,0 17,0 C26.3888407,0 34,7.61115925 34,17 C34,26.3888407 26.3888407,34 17,34 C7.61115925,34 0,26.3888407 0,17 Z" id="icon" opacity="0.25"></path><path d="M6.95454545,16.2272727 L14.6818182,16.2272727 C15.1085837,16.2272727 15.4545455,16.5732345 15.4545455,17 C15.4545455,17.4267655 15.1085837,17.7727273 14.6818182,17.7727273 L6.95454545,17.7727273 C6.52777997,17.7727273 6.18181818,17.4267655 6.18181818,17 C6.18181818,16.5732345 6.52777997,16.2272727 6.95454545,16.2272727 Z M17.7727273,4.63636364 L17.7727273,14.6818182 C17.7727273,15.1085837 17.4267655,15.4545455 17,15.4545455 C16.5732345,15.4545455 16.2272727,15.1085837 16.2272727,14.6818182 L16.2272727,4.63636364 C16.2272727,4.20959815 16.5732345,3.86363636 17,3.86363636 C17.4267655,3.86363636 17.7727273,4.20959815 17.7727273,4.63636364 Z M17,20.0909091 C15.292938,20.0909091 13.9090909,18.707062 13.9090909,17 C13.9090909,15.292938 15.292938,13.9090909 17,13.9090909 C18.707062,13.9090909 20.0909091,15.292938 20.0909091,17 C20.0909091,18.707062 18.707062,20.0909091 17,20.0909091 Z" id="icon-2"></path></g></g></g></g></g></svg>
                            <p><span>{props.activeTime}</span></p>
                            <h1>{translate('app.recipeDetails.active_time')}</h1>
                        </div>
                        <div className="recipes-time-item">
                            <svg width="34px" height="34px" viewBox="0 0 34 34" version="1.1" xmlns="http://www.w3.org/2000/svg"
                            // xmlns:xlink="http://www.w3.org/1999/xlink"
                            ><g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="Recipes-Details-Screen" transform="translate(-846.000000, -1074.000000)" fill="#FF5F00" fillRule="nonzero"><g id="time" transform="translate(735.000000, 1044.000000)"> <g id="Group-5-Copy-7" transform="translate(111.000000, 30.000000)"><g id="icon-1"><path d="M0,17 C0.0618181818,16.8964545 17,17 17,17 C17,17 17,0 17,0 C26.3888407,0 34,7.61115925 34,17 C34,26.3888407 26.3888407,34 17,34 C7.61115925,34 0,26.3888407 0,17 Z" id="icon" opacity="0.25"></path><path d="M6.95454545,16.2272727 L14.6818182,16.2272727 C15.1085837,16.2272727 15.4545455,16.5732345 15.4545455,17 C15.4545455,17.4267655 15.1085837,17.7727273 14.6818182,17.7727273 L6.95454545,17.7727273 C6.52777997,17.7727273 6.18181818,17.4267655 6.18181818,17 C6.18181818,16.5732345 6.52777997,16.2272727 6.95454545,16.2272727 Z M17.7727273,4.63636364 L17.7727273,14.6818182 C17.7727273,15.1085837 17.4267655,15.4545455 17,15.4545455 C16.5732345,15.4545455 16.2272727,15.1085837 16.2272727,14.6818182 L16.2272727,4.63636364 C16.2272727,4.20959815 16.5732345,3.86363636 17,3.86363636 C17.4267655,3.86363636 17.7727273,4.20959815 17.7727273,4.63636364 Z M17,20.0909091 C15.292938,20.0909091 13.9090909,18.707062 13.9090909,17 C13.9090909,15.292938 15.292938,13.9090909 17,13.9090909 C18.707062,13.9090909 20.0909091,15.292938 20.0909091,17 C20.0909091,18.707062 18.707062,20.0909091 17,20.0909091 Z" id="icon-2"></path></g></g></g></g></g></svg>
                            <p><span>{props.totalTime}</span></p>
                            <h1>{translate('app.recipeDetails.total_time')}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default RecipeValues
