// export const WEB_URL = 'https://app.tendoo.com';
export const WEB_URL = 'https://nesolutendoowebtest20191003073447.azurewebsites.net';
// export const WEB_URL = 'http://localhost:3000';
// export const BASE_URL = 'https://app.tendoo.com/api';
export const BASE_URL = 'https://nesolutendoowebtest20191003073447.azurewebsites.net/api/';
// export const IMAGE_BASE_URL = 'https://app.tendoo.com';
export const IMAGE_BASE_URL = 'https://nesolutendoowebtest20191003073447.azurewebsites.net';

export const CURRENT_USER = 'CURRENT_USER'

export const LOG_OUT = 'LOG_OUT'

export const LOADING = 'LOADING'
